<!--
 * @Description:管理员权限 
 * @Author: ChenXueLin
 * @Date: 2021-10-18 14:41:03
 * @LastEditTime: 2022-07-15 16:01:25
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="userId">
              <el-input
                v-model="searchForm.userId"
                placeholder="用户ID"
                title="用户ID"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="userName">
              <el-input
                v-model="searchForm.userName"
                placeholder="用户名称"
                title="用户名称"
              ></el-input>
            </el-form-item>

            <el-form-item class="search-item--1" prop="createdBy">
              <el-input
                v-model="searchForm.createdBy"
                placeholder="创建人"
                title="创建人"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="创建时间"
                ref="effectiveDatePicker"
                v-model="searchForm.createTime"
                title="创建时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="创建时间（始）"
                end-placeholder="创建时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i class="e6-icon-add_line" title="添加管理员" @click="addAdmin"></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          ref="elTable"
        >
          <el-table-column type="selection" width="40"></el-table-column>
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
            <template slot-scope="{ row }">
              <div class="table-label" v-if="column.fieldName === 'label'">
                <span
                  v-for="(item, index) in row.label"
                  :key="index"
                  class="label-box"
                  >{{ item }}</span
                >
              </div>
              <span
                v-else-if="column.fieldName === 'status'"
                :class="[
                  'status',
                  row.status === 1
                    ? 'finish'
                    : row.status === 2
                    ? 'ongoing'
                    : ''
                ]"
              >
                已完成
              </span>

              <!-- <span v-elseif="column.fieldName === 'chargeCode'">
                <el-button type="text" @click="handleGoToBillDetail(row)">
                  {{ row[column.fieldName] }}
                </el-button>
              </span> -->
              <span v-else>{{ row[column.fieldName] }}</span>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList(scope.row)"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section" ref="paginationWrapper">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <!-- 添加管理员 -->
    <add-diolag
      :addAdminDialog="addAdminDialog"
      @handleClose="handleClose"
      @refresh="queryList"
      type="add"
    ></add-diolag>
  </div>
</template>

<script>
import base from "@/mixins/base";
import { printError } from "@/utils/util";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { getAdminList, adminDisEnable, deleteAdmin, adminEnable } from "@/api";
import addDiolag from "./addDiolag.vue";
export default {
  name: "adminManage",
  data() {
    return {
      loading: false,
      searchForm: {
        userId: "", //用户id
        userName: "", //用户名称
        createdBy: "", //创建人
        startCreatedTime: "", //开始时间
        endCreatedTime: "", //结束时间
        createTime: [],
        pageIndex: 1,
        pageSize: 20
      },
      total: 0,
      columnData: [
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "用户ID",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userName",
          display: true,
          fieldLabel: "用户名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "postName",
          display: true,
          fieldLabel: "岗位",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userStatus",
          display: true,
          fieldLabel: "用户状态",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "privilegeNum",
          display: true,
          fieldLabel: "权限",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createBy",
          display: true,
          fieldLabel: "创建人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createTime",
          display: true,
          fieldLabel: "创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          mode: "color",
          color: "#79a3f2"
        }
      ], //表头
      tableData: [], // 表格数据
      addAdminDialog: false,
      refTable: "elTable",
      queryListAPI: getAdminList
    };
  },
  watch: {
    // 创建开始时间
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.startCreatedTime = val[0] || "";
        this.searchForm.endCreatedTime = val[1] || "";
      }
    }
  },
  components: { addDiolag },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  created() {
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    // 获取页面操作权限
    getOperateList(row) {
      let operates = [
        {
          label: "详情",
          id: 1
        },
        {
          label: "删除",
          id: 4
        }
      ];
      if (row.userStatus == "禁用") {
        operates.push({
          label: "启用",
          id: 3
        });
      } else {
        operates.push({
          label: "停用",
          id: 2
        });
      }
      return operates;
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.routerPush({
          name: "userManage/adminDetail",
          params: {
            refresh: true,
            id: row.managerId
          }
        });
      }
      if (val.id == 2) {
        this.stopUse(row.managerId, row.versionNumber);
      }
      if (val.id == 3) {
        this.enableAdmin(row.managerId, row.versionNumber);
      }
      if (val.id == 4) {
        this.deleteAdmin(row);
      }
    },
    //启用管理员
    async enableAdmin(id, versionNumber) {
      try {
        this.loading = true;
        let res = await adminEnable({
          id,
          versionNumber
        });
        if (res.code == "OK") {
          this.$message.success("启用成功");
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //停用管理员
    async stopUse(id, versionNumber) {
      try {
        this.loading = true;
        let res = await adminDisEnable({
          id,
          versionNumber
        });
        if (res.code == "OK") {
          this.$message.success("禁用成功");
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //删除管理员
    deleteAdmin(row) {
      this.$confirm("删除管理员", "是否确定删除此管理员？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.deleteAdminReq(row);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    //删除请求
    async deleteAdminReq(row) {
      try {
        this.loading = true;
        let res = await deleteAdmin({
          id: row.managerId,
          versionNumber: row.versionNumber
        });
        if (res.code == "OK") {
          this.$message.success("删除成功");
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //点击添加管理员
    addAdmin() {
      this.addAdminDialog = true;
    },
    //关闭弹框
    handleClose() {
      this.addAdminDialog = false;
    }
  }
};
</script>
<style lang="scss" scoped></style>
